import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dialog, Spinner } from "@material-tailwind/react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import GigUpdate from "./GigUpdate";
import UserList from "../GigsComponents/UserList";
import GigReview from "../GigsComponents/GigReview";
import { MdModeEdit } from "react-icons/md";
import {
  addGig,
  Gig,
  GigDelete,
  GigDetails,
  GigProps,
  GigUser,
  RootObject,
} from "../../../utils/gig";
import { formatDistanceToNow } from "date-fns";

const GigDetailsList = () => {
  const [gigData, setGigData] = useState<Gig | undefined>();
  const [gigSubTasks, setGigSubTasks] = useState<any[]>([]);
  const [canApply, setCanApply] = useState(false);
  const [status, setStatus] = useState(false);
  const page = 1;
  const [loading, setLoading] = useState(false);
  const [tabs] = useState([
    { name: "Gig Details" },
    { name: "Applied Freelancer" },
    { name: "Hire" },
    { name: "Content Submit" },
  ]);
  const [activeTab, setActiveTab] = useState<String>("Gig Details");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newSpecification, setnewSpecification] = useState(false);
  const [repeaterIndex, setRepeaterIndex] = useState<number | undefined>(
    undefined
  );
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(1);
  const [specificationTitle, setSpecificationTitle] = useState("");
  const history = useNavigate();
  const [gigUsers, setGigUsers] = useState<GigUser[]>();
  const [reFetch, setReFetch] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
    setUpdateLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const GigPostData: GigProps = {
          page: page,
          id: id!,
          limit: 5,
        };
        const response: RootObject = await GigDetails(GigPostData);
        if (response.status === 200) {
          setGigData(response.data.gig);
          setCanApply(response.data.gig.canApply);
          setStatus(response.data.gig.status);
          setGigSubTasks(response.data.subTask);
          setGigUsers(response.data.results);
          setLoading(false);
          setSpecificationTitle((prevTitle) => {
            const newTitle =
              repeaterIndex !== undefined
                ? response.data.gig.specifications?.[repeaterIndex]?.title || ""
                : "";
            return newTitle !== "" ? newTitle : prevTitle;
          });
        } else {
          console.log("Error", response);
        }

    
      } catch (error) {
        console.log("Error", error);
      }
     

    })();
    console.log("reFetch", reFetch);
  }, [updateLoading, reFetch]);

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await GigDelete({ _id: id, page: 1 });
        toast.success("Gig Deleted successfully");
        history("/gigList");
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };
  const formatPostDate = (dateString: any) => {
    if (!dateString) return "";
    const postDate = new Date(dateString);
    return formatDistanceToNow(postDate, { addSuffix: true });
  };

  const handleEnableCanApply = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanApply(event.target.checked);
    const formData = new FormData();
    formData.append("canApply", canApply ? "false" : "true");
    formData.append("_id", id!);
    await addGig(formData);
  };
  const handleStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
    const formData = new FormData();
    formData.append("status", status ? "false" : "true");
    formData.append("_id", id!);
    await addGig(formData);
  };
  
  return (
    <main>
      <section className="max-w-7xl m-auto">
        <div className="grid grid-cols-12 space-x-3">
          <div className="col-span-8 border border-gray-300 rounded-lg bg-white">
            <div className="p-8">
              <div className="flex justify-between pb-2">
                <div>
                  <div className="basicgigingos">
                    <p className="">
                      Posted {formatPostDate(gigData?.createdAt)}
                    </p>
                  </div>
                  <h3 className="gigmaintitle">{gigData?.title}</h3>
                </div>
                <div>
                  {gigUsers &&
                    gigUsers?.filter((ele) => ele.gigStatus === "approved")
                      .length === 0 && (
                      <Button
                        onClick={() => {
                          handleOpen();
                          setStep(7);
                        }}
                        className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none h-12 w-12 flex items-center justify-center"
                      >
                        <MdModeEdit size={20} className="text-white" />
                      </Button>
                    )}
                </div>
              </div>

              <div className="grid grid-cols-4 space-x-5 gigsingletab">
                {tabs.map((ele, index) => (
                  <div
                    key={index}
                    className={`gigsingleinnertab  text-white flex justify-center items-center ${activeTab === ele.name ? "bg-bluePostaam-600" : ""
                      }`}
                    onClick={() => setActiveTab(ele.name)}
                    role="button"
                  >
                    <h5
                      className={`${activeTab === ele.name ? "text-white" : "text-black"
                        }`}
                    >
                      {ele.name}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
            {loading && (
              <div className=" flex justify-center items-center h-[60vh]">
                <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
              </div>
            )}
            {!loading && (
              <div className="">
                {/* Applied Freelancer start */}
                {activeTab == "Applied Freelancer" && (
                  <div className="space-y-4">
                    <div className="border-gray-300 border-t p-8 space-y-4">
                      {gigUsers
                        ?.filter((ele) => ele.gigStatus === "applied" && ((ele.subTask === false && gigSubTasks.length > 0) || gigSubTasks.length === 0))
                        .map((ele, index) => (
                          <UserList
                            data={ele}
                            buttonAction={"Hire"}
                            key={index}
                            setReFetch={setReFetch}
                            reFetch={reFetch}
                            requirePosting={gigData?.requirePosting}
                          />
                        ))}
                      {gigUsers?.filter((ele) => ele.gigStatus == "applied")
                        .length! == 0 && <div>No Freelancer Applied</div>}
                    </div>
                  </div>
                )}
                {/* Applied Freelancer end */}
                {/* Selected Freelancer start */}
                {activeTab == "Hire" && (
                  <div>
                    <div className="space-y-4">
                      <div className="border-gray-300 border-t p-8 space-y-4">
                        {gigUsers
                          ?.filter(
                            (ele) =>
                              (ele.gigStatus === "completed" ||
                                ele.gigStatus === "approved" ||
                                ele.gigStatus === "requestforposting") && ((ele.subTask === false && gigSubTasks.length > 0) || gigSubTasks.length === 0)
                          ).map((ele, index) => (
                            <UserList
                              data={ele}
                              buttonAction={"Hired"}
                              key={index}
                              setReFetch={setReFetch}
                              reFetch={reFetch}
                              requirePosting={gigData?.requirePosting}
                            />
                          ))}
                        {gigUsers?.filter((ele) => ele.gigStatus == "completed" || ele.gigStatus == 'approved' || ele.gigStatus == 'requestforposting')
                          .length! == 0 && <div>No Freelancer Hired</div>}

                      </div>
                    </div>
                    {/* ended */}
                  </div>
                )}
                {/* Selected Freelancer end */}
                {/* Content Posted Submit start */}
                {activeTab == "Content Submit" && (
                  <div className="space-y-4">
                    <div className="border-gray-300 border-t p-8 space-y-4">
                      {/* <GigPostedByUsers /> */}
                      {

                      }
                    </div>
                  </div>
                )}
                {/* Content Posted Submit start */}
                {/* Gig details start */}
                {activeTab == "Gig Details" && (
                  <div className="space-y-4">
                    {/* Gig details start */}
                    <div className="space-y-4">
                      {gigSubTasks && gigSubTasks?.length > 0 && <div className="border-gray-300 border-t">
                        <div>
                          <div className="border-b gap-2">
                            <div className="px-8 py-5 flex flex-row items-center justify-between">
                              <h5 className="text-gray-600 text-2xl">
                                {gigData?.gigType}
                              </h5>
                              {gigUsers &&
                                gigUsers?.filter(
                                  (ele) => ele.gigStatus == "approved"
                                ).length == 0 && <button>Add Sub Task</button>}
                            </div>
                          </div>
                          <div>
                            <div className="px-8 py-5 space-y-4">
                              <div className="flex justify-between">
                                <div>
                                  <h5 className="text-gray-700 font-medium text-lg">
                                    {gigData?.action}
                                  </h5>
                                  <div></div>
                                  <div className="grid grid-cols-12">
                                    <div className="col-span-6">
                                      <div className="flex gap-2">
                                        <p className="text-gray-700 font-medium">
                                          Business:
                                        </p>
                                        <p className="font-mulish text-gray-600">
                                          {gigData?.businessName}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="flex gap-2">
                                    <p className="text-gray-700 font-medium">
                                      Title:
                                    </p>
                                    <p className="font-mulish text-gray-600">
                                      {gigData?.title}
                                    </p>
                                  </div>
                                  <div className="flex gap-2">
                                    <p className="text-gray-700 font-medium">
                                      Description:
                                    </p>
                                    <p className="font-mulish text-gray-600 w-550">
                                      {gigData?.description}
                                    </p>
                                  </div>
                                  <div className="">
                                    <p className="font-500 font-Roboto text-gray-600 pb-2">
                                      Tags
                                    </p>
                                    <div className="flex gap-2 flex-wrap skillpills">
                                      <p className="">
                                        {gigData?.tags.length === 0
                                          ? "No Tag Avaliable"
                                          : gigData?.tags}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {gigUsers &&
                                  gigUsers?.filter(
                                    (ele) => ele.gigStatus == "approved"
                                  ).length == 0 && (
                                    <div className="space-x-2">
                                      <button
                                        className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                                        onClick={() => {
                                          handleOpen();
                                          setStep(8);
                                        }}
                                      // disabled
                                      >
                                        <MdModeEdit
                                          size={20}
                                          className="text-white"
                                        />
                                      </button>

                                      <button
                                        className="p-3 rounded-full bg-red-600/70 shadow-none hover:shadow-none"
                                        disabled
                                      >
                                        <AiFillDelete
                                          size={20}
                                          className="text-white"
                                        />
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}

                      {gigData && gigData.product && (
                        <div className="bg-white px-5 leading-8 flex justify-between flex-col mt-4 border-gray-300 border-t">
                          <div className="flex justify-between items-center">
                            <h5 className="text-gray-600 text-2xl mb-1">
                              Product
                            </h5>
                            <Button
                              onClick={() => {
                                handleOpen();
                                setStep(6);
                              }}
                              className="p-3 rounded-full bg-bluePostaam-600 mr-3 mt-3 shadow-none hover:shadow-none"
                            >
                              <MdModeEdit size={20} className="text-white" />
                            </Button>
                          </div>
                          <div className="product-card flex gap-3 items-center">
                            <img
                              src={gigData?.product.image}
                              alt={gigData?.product.productName}
                              className="w-32 h-32 object-contain border-[1px] rounded-md"
                            />
                            <ul>
                              <li>
                                <a
                                  href={`/productDetails/${gigData?.product._id}`}
                                  target="_blank"
                                  className="product-link text-xl"
                                >
                                  {gigData?.product.productName}
                                </a>
                              </li>
                              <li>Rs. {gigData?.product.price}</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Gig details end */}
                    <div className="border-gray-300 border-t p-8">
                      <div>
                        <div className="flex flex-row items-center justify-between gap-2 pb-2">
                          <h5 className="text-gray-600 text-2xl">Preference</h5>
                          {gigUsers &&
                            gigUsers?.filter(
                              (ele) => ele.gigStatus == "approved"
                            ).length == 0 && (
                              <Button
                                onClick={() => {
                                  handleOpen();
                                  setStep(2);
                                }}
                                className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                              >
                                <MdModeEdit size={20} className="text-white" />
                              </Button>
                            )}
                        </div>
                        <div>
                          <div className="flex gap-2">
                            <p className="text-gray-700 font-medium">
                              Number of Creator:
                            </p>
                            <p className="text-gray-600">
                              {gigData?.numberofcreator
                                ? gigData?.numberofcreator
                                : "Not Available"}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div className="flex gap-2">
                            <p className="text-gray-700 font-medium">
                              Content Type:
                            </p>
                            <p className="text-gray-600 capitalize">
                              {gigData?.gigType
                                ? gigData?.gigType
                                : "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-gray-300 border-t p-8">
                      <div>
                        <div className="flex flex-row items-center justify-between gap-2 pb-2">
                          <h5 className="text-gray-600 text-2xl">Multi Post</h5>
                          {gigUsers &&
                            gigUsers?.filter(
                              (ele) => ele.gigStatus == "approved"
                            ).length == 0 && (
                              <Button
                                onClick={() => {
                                  handleOpen();
                                  setStep(3);
                                }}
                                className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                              >
                                <MdModeEdit size={20} className="text-white" />
                              </Button>
                            )}
                        </div>
                        <div className="flex gap-2">
                          <p className="text-gray-700 font-medium">
                            Number of Images:
                          </p>
                          <p className="font-mulish text-gray-600">
                            {gigData?.numberofimage
                              ? gigData?.numberofimage
                              : "Not Available"}
                          </p>
                        </div>

                        <div>
                          <div className="flex gap-2">
                            <p className="text-gray-700 font-medium">
                              Number of Videos:
                            </p>
                            <p className=" text-gray-600">
                              {gigData?.numberofvideo
                                ? gigData?.numberofvideo
                                : "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-gray-300 border-t p-8 flex justify-between">
                      <div className="flex flex-col space-y-1 w-full">
                        <div className="flex flex-row items-center justify-between gap-2 pb-2">
                          <h5 className="text-gray-600 text-2xl">
                            Tags & Skills
                          </h5>
                          {gigUsers &&
                            gigUsers?.filter(
                              (ele) => ele.gigStatus == "approved"
                            ).length == 0 && (
                              <Button
                                onClick={() => {
                                  handleOpen();
                                  setStep(1);
                                }}
                                className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                              >
                                <MdModeEdit size={20} className="text-white" />
                              </Button>
                            )}
                        </div>
                        <p className="font-500 font-Roboto text-gray-600">
                          Experties Level:
                          <span className="font-normal pl-2 capitalize">
                            {gigData?.level}
                          </span>
                        </p>

                        <div className="grid grid-cols-12 space-x-3">
                          <div className="col-span-6 pb-3">
                            <p className="font-500 font-Roboto text-gray-600 pb-2">
                              Tags
                            </p>
                            <div className="flex gap-2 flex-wrap skillpills">
                              {gigData?.tags.map((tag, index) => {
                                return (
                                  <p key={index} className="">
                                    #{tag}
                                  </p>
                                );
                              })}
                            </div>
                          </div>

                          <div className="col-span-6 ">
                            <p className="font-500 font-Roboto text-gray-600 pb-2">
                              Skills
                            </p>
                            <div className="flex gap-2 flex-wrap skillpills">
                              {gigData?.skills.map((skill, index) => {
                                return (
                                  <p key={index} className="">
                                    {skill}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-center justify-between gap-2 px-8 py-5 border-b border-t">
                      <h5 className="text-gray-600 text-2xl font-medium">
                        Specifications
                      </h5>
                      {gigUsers &&
                        gigUsers?.filter((ele) => ele.gigStatus == "approved")
                          .length == 0 && (
                          <Button
                            className="bg-bluePostaam-600 rounded-md"
                            onClick={() => {
                              setStep(4);
                              setnewSpecification(true);
                              handleOpen();
                            }}
                          >
                            Add Specification
                          </Button>
                        )}
                    </div>

                    <div className="">
                      {gigData?.specifications.map((spec, index) => (
                        <div key={index} className="">
                          <div className="flex flex-col w-full border-b py-8 px-8">
                            <div className="flex justify-between items-center">
                              <p className="text-gray-600 text-lg font-semibold w-full">
                                {spec.title}
                              </p>
                              {gigUsers &&
                                gigUsers?.filter(
                                  (ele) => ele.gigStatus == "approved"
                                ).length == 0 && (
                                  <div className="space-x-2 flex">
                                    <Button
                                      onClick={() => {
                                        setnewSpecification(false);
                                        handleOpen();
                                        setStep(4);
                                        setRepeaterIndex(index);
                                        setSpecificationTitle(spec.title);
                                      }}
                                      className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                                    >
                                      <MdModeEdit
                                        size={20}
                                        className="text-white"
                                      />
                                    </Button>
                                    <Button
                                      className="p-3 rounded-full bg-red-600/70 shadow-none hover:shadow-none"
                                      disabled
                                    >
                                      <AiFillDelete
                                        size={20}
                                        className="text-white"
                                      />
                                    </Button>
                                  </div>
                                )}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: spec.description,
                              }}
                              style={{
                                color: "#92939a",
                                marginTop: "18px",
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* Gig details end */}
              </div>
            )}
          </div>
          <div className="col-span-4 space-y-3">
            <div className="bg-white border border-gray-300 rounded-lg">
              <div className="p-6 flex items-center space-x-4">
                {gigUsers &&
                  gigUsers?.filter((ele) => ele.gigStatus == "applied").length ==
                  0 && (
                    <div className="flex">
                      <AiFillDelete size={26} color="#f44336" />

                      <Button
                        className="hover:shadow-transparent shadow-transparent bg-transparent p-0 text-red-500 text-left text-base capitalize font-medium"
                        onClick={() => handleDelete(gigData?._id!)}
                      >
                        Delete
                      </Button>

                    </div>
                  )}

              </div>
              <div className="p-6 flex items-center space-x-4">
                <div className="-mb-2">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={canApply}
                      onChange={handleEnableCanApply}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bluePostaam-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Enable Button</span>
                  </label>
                </div>
                <div className="-mb-2">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={status}
                      onChange={handleStatus}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bluePostaam-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Status</span>
                  </label>
                </div>

              </div>
              <div className="border-t px-6 py-4 justify-between">
                <div className="flex items-center justify-between">
                  <h6 className="text-gray-800 font-Roboto text-xl font-medium">
                    Price
                  </h6>
                  {gigUsers &&
                    gigUsers?.filter((ele) => ele.gigStatus == "approved")
                      .length == 0 && (
                      <Button
                        onClick={() => {
                          handleOpen();
                          setStep(5);
                        }}
                        className="p-3 rounded-full bg-bluePostaam-600 shadow-none hover:shadow-none"
                      >
                        <MdModeEdit size={20} className="text-white" />
                      </Button>
                    )}
                </div>
                <div>
                  <div>
                    <div className="flex gap-2">
                      <span className="font-bold font-Roboto text-gray-600">
                        Duration Days:
                      </span>
                      <p className="font-mulish text-gray-600">
                        {gigData?.adDuration
                          ? gigData.adDuration
                          : "Not Available"}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-bold font-Roboto text-gray-600">
                        Timeframe:
                      </span>
                      <p className="font-mulish text-gray-600">
                        {gigData?.submissionTimeframe
                          ? gigData.submissionTimeframe
                          : "Not Available"}
                      </p>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-bold font-Roboto text-gray-600">
                        Offer Amount / Offer From:
                      </span>
                      <p className="font-mulish text-gray-600">
                        {gigData?.offerAmountFrom
                          ? gigData.offerAmountFrom
                          : "Not Available"}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-bold font-Roboto text-gray-600">
                        Offer To:
                      </span>
                      <p className="font-mulish text-gray-600">
                        {gigData?.offerAmountTo
                          ? gigData.offerAmountTo
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border border-gray-300 p-6 rounded-lg space-y-4">
              <GigReview />
            </div>
          </div>
        </div>
      </section>
      <section>
        <Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
          <GigUpdate
            step={step}
            id={id!}
            handleOpen={handleOpen}
            repeaterIndex={repeaterIndex}
            newSpecification={newSpecification}
            setUpdateLoading={setUpdateLoading}
            specificationTitle={specificationTitle}
          />
        </Dialog>
      </section>
    </main>
  );
};

export default GigDetailsList;
