import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Layout from "./components/Layout";
import LoginForm from "./pages/auth/LoginForm";
import ForgetPasswod from "./pages/auth/ForgetPassword";
import Register from "./pages/auth/Register";
import { ToastContainer } from "react-toastify";
import Otp from "./pages/auth/Otp";
import ForgetOtpPws from "./pages/auth/ForgetOtpPws";
import AddGig from "./components/forms/gigForm/AddGig";
import GigList from "./components/tables/GigList";
import Gigdetails from "./pages/detailsPage/git_details";
import ProductList from "./components/tables/ProductList";
import ProductDetails from "./components/details/productDetails/ProductDetails";
import Revenu from "./pages/revenu";
import Booking from "./components/tables/sales/Booking";
import ProdcutAdd from "./components/forms/ProdcutAdd";
import AccountAndSettings from "./pages/AccountAndSettings";
import GigDetailsList from "./components/details/gig/GigDetailsList";
import Order from "./components/tables/sales/Order";
import OrderDetails from "./components/details/OrderDetails";
import SingleBooking from "./components/details/SingleBooking";

interface Category {
  name: string;
  commission: string;
}

function App() {
  const handleAddCategory = (name: string, commission: string) => {
    const newCategory: Category = {
      name,
      commission,
    };
  };
  const isAuthenticated = !!localStorage.getItem("token");
  const { id } = useParams();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/forgetPassword" element={<ForgetPasswod />} />
        <Route path="/register" element={<Register />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/forgetOtpPws" element={<ForgetOtpPws />} />

        <Route path="*" Component={LoginForm} />
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/gitDetails" element={<Gigdetails />} />
          <Route path="/addGig" element={<AddGig />} />
          <Route path="/gigList" element={<GigList />} />
          <Route path="/productList" element={<ProductList />} />
          <Route path="/productDetails/:id" element={<ProductDetails />} />
          <Route path="/gigDetails/:id" element={<GigDetailsList />} />
          <Route path="/finance" element={<Revenu />} />
          <Route path="/profile" element={<AccountAndSettings />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/singleBooking/:id" element={<SingleBooking />} />
          <Route path="/addProduct" element={<ProdcutAdd />} />
          <Route path="/order" element={<Order />} />
          <Route path="/SingleOrder/:id" element={<OrderDetails />} />
        </Route>
      </Routes>
      <ToastContainer style={{ zIndex: 99999 }} />
    </BrowserRouter>
  );
}

export default App;
