import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { GigApprove, GigChats, GigChatUserList, GigUser, GigUserListReultProps, ResulUsertChatsProps } from "../../../utils/gig";
import UserBox from "./UserBox";
import { FaCross } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

interface UserListProps {
  buttonAction: String;
  data: GigUser;
  setReFetch: (value: boolean) => void;
  reFetch: boolean;
  requirePosting?: boolean;
}
const UserList = ({
  buttonAction,
  data,
  setReFetch,
  reFetch,
  requirePosting
}: UserListProps) => {
  const [gigUserChatList, setGigUserChatList] = useState<ResulUsertChatsProps[]>([]);

  const getUserChats = async (id: string) => {
    try {
      const responsChatUserList = await GigChats(
        id
      );
      setGigUserChatList(responsChatUserList.results);
    } catch (error) {
      console.error("Error fetching gig user chat list:", error);
    }
  };


  return (
    <div>
      <UserBox data={data} buttonAction={buttonAction} requirePosting={requirePosting} setReFetch={setReFetch} reFetch={reFetch} getUserChats={getUserChats} />
      {
        gigUserChatList.length > 0 && (<div className="fixed top-0 bottom-0 right-0 left-0 bg-black/20 " style={{ zIndex: 9999999999999 }}>
          <div className="bg-gray-50 w-1/3 h-1/2 top-1/4 left-1/2 -translate-x-1/2 absolute p-5 border rounded-lg" >
            <div className="flex justify-between mb-5">
              <p className="text-sm text-gray-500"> {gigUserChatList.length} Chats</p>
              <IoCloseCircle className="h-8 w-8 text-gray-500" onClick={() => setGigUserChatList([])} />
            </div>

            {
              gigUserChatList.map((item, index) => {
                return (
                  <div key={index} className="flex items-center justify-between">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="rounded-full border">
                          <img src={item.receivedBy.profileFile} alt="" className="w-14 h-14 rounded-full" />
                        </div>
                        <div className="ml-2">
                          <p>{item.receivedBy.fullname}</p>
                          <p>{item.message}</p>
                        </div>
                      </div>



                      <button className="bg-blue-500 text-white rounded-full px-4 py-1 ml-2" onClick={() => getUserChats(item._id)}>Release</button>
                    </div>

                  </div>
                )
              })
            }
          </div>


        </div>)
      }
    </div>


  );
};

export default UserList;
