import { FaStar, FaStarHalfStroke } from "react-icons/fa6";
const GigReview = () => {
  return (
    <>
      <div>
        <h6 className="text-gray-800 font-Roboto text-xl font-medium pb-2">
          Your feedback to Freelancer
        </h6>
        <div className="space-y-3">
          <div className="flex flex-row gap-0.5">
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStarHalfStroke size={27} color="#ffbd42" />
          </div>

          <p className="">Review here for freelancer</p>
          <button className="border border-bluePostaam-700 text-bluePostaam-700 hover:bg-bluePostaam-700 hover:text-white w-full py-2 rounded-full">
            Write A Review
          </button>
        </div>
      </div>
      <div className="space-y-3">
        <h6 className="text-gray-800 font-Roboto text-xl font-medium">
          Freelancer feedback
        </h6>
        <div>
          <div className="flex flex-row gap-0.5 pb-1">
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStar size={25} color="#ffbd42" />
            <FaStarHalfStroke size={27} color="#ffbd42" />
          </div>

          <p className="">If review given show here.</p>
          <p>Freelancer hasn't provided feedback yet.</p>
        </div>
      </div>
    </>
  );
};

export default GigReview;
