import { useEffect, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { Avatar, Badge } from "@material-tailwind/react";
import { RootBusinessProfile, getBusinessProfile } from "../../utils/business";
import { Results } from "../../utils/user";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [businessProfile, setBusinessProfile] = useState<RootBusinessProfile>();
  const [user, setUser] = useState<Results>();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessProfile(data);
    })();
  }, []);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("token")!));
  }, []);

  const handleButtonClick = () => {
    setMenuVisible(!menuVisible);
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <header>
      <div className="flex items-center">
        {/* Notification menu started */}
        <div className=" inline-block text-left z-20 relative">
          <button onClick={handleButtonClick}>
            <Badge className="rounded-md shadow-sm bg-Bgc custom-badge mr-1 mt-1 border-2 border-[#F7F8FC]">
              <IoMdNotifications size={25} className="text-[#C5C7CD]" />
            </Badge>
          </button>
          <div
            className={`${
              menuVisible ? "" : "hidden"
            } origin-top-right absolute  mt-3 w-72 left-[-120px] rounded-md shadow_box bg-white`}
          >
            <div>
              <p className="text-center text-base outline-none py-4 px-4 font-Roboto text-[#A4A6B3] font-[500]">
                No Notificaion
              </p>
            </div>
          </div>
        </div>
        {/* Notification menu ended */}

        {/* profile started */}
        <div className="h-8 mx-4 w-[2px] bg-[#DFE0EB]"></div>
        <div
          className="flex items-center relative h-full"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <p className="font-Roboto font-[500] text-[14px] text-[#252733] tracking-wide mx-2 lg:block md:block sm:hidden hidden">
            {user ? user.user.fullname : "Guest"}
          </p>
          <Avatar
            src={
              businessProfile?.results && businessProfile.results.profileFile
                ? businessProfile.results.profileFile
                : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.explicit.bing.net%2Fth%3Fid%3DOIP.0CZd1ESLnyWIHdO38nyJDAAAAA%26pid%3DApi&f=1&ipt=228fe027818f6bf835fee5cb4a24a5d70e35bf33d54e90323188344aaed4098f&ipo=images"
            }
            alt="avatar"
            withBorder={true}
            className="p-0.5 bg-white border-gray-400 border-2"
          />
          {isOpen && (
            <div className="absolute right-0 bottom-0 top-[50px] px-1 z-50 w-[195px] h-[103px] bg-white shadow_box">
              <div
                onClick={() => navigate("/profile")}
                className="px-3 cursor-pointer hover:bg-gray-200 flex-col flex py-[0.8rem] mt-1 font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide"
              >
                Edit Profile
              </div>
              <hr />
              <div
                onClick={() => logoutUser()}
                className=" hover:bg-gray-200 flex-col flex font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide"
              >
                <p className="px-3 hover:bg-gray-200 cursor-pointer flex-col flex py-[0.98rem] pb-[0.7rem] font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide">
                  Logout
                </p>
              </div>
            </div>
          )}
        </div>
        {/* Profile ended */}
      </div>
    </header>
  );
};
export default Header;
